const croatian = {
    ISO: 'HR',
    Distributer: 'Distributer za BIH:',
    DistributerRS: 'Distributer za SRB:',
    DistributerHR: 'Distributer za HR:',
    Pretplacen: 'Uspješno ste se pretplatili',
    Copyright: '©2019-2022 Bio Plar Sva prava zadržana',
    UsloviKoristenja: 'Uslovi koristenja',
    Pocetna: 'Početna',
    Onama: 'O nama',
    Katalog: 'Katalog',
    KontaktirajteNas: 'Kontaktirajte nas',
    ZastoPrirodno: 'Zašto prirodno',
    Pretrazuj: 'Pretražuj',
    PopularnoOveSedmice: 'POPULARNO OVE SEDMICE',
    DodajUkorpu: 'Naruči',
    prirodno100: '100% Prirodno',
    KontentJedan: '100% prirodni preparati potvrđeni ISO i GMP certifikatima, koji jamče kvalitetu i ujednačenost proizvoda \n\n Sastojci vrhunske kvalitete koje je teško pronaći pojedinačno',
    Naglasci: 'NAGLASCI',
    BeskompromisnaKvaliteta: 'Beskompromisna kvaliteta',
    KljucVasegZdravlja: 'KLJUČ VAŠEG ZDRAVLJA NALAZI SE U PRIRODI',
    ZbogTogaSviNasiProizvodi: 'Zbog toga svi naši proizvodi zadovoljavaju 3 kriterija:',
    KontentDva: 'korišteni sastojci najviše su kvalitete i isključivo prirodnog podrijetla, što je potvrđeno međunarodnim ISO i GMP standardima',
    KontentTri: 'osigurana kroz ponudu proizvoda razvijenih u suradnji sa međunarodnim firmama sa 40 godišnjim iskustvom izrade prirodnih preparata',
    KontentCetiri: 'uz 100% prirodno podrijetlo naši sastojci uz redovitu upotrebu imaju dokazano djelovanje na ublažavanje tegoba',
    NakonStoPopunite: 'Nakon što popunite formular kontaktirat ćemo Vas u radnom vremenu pon -sub 9h - 21h.',
    ImeIprezime: 'Ime i prezime',
    BrojTelefona: 'Broj telefona',
    NazoviMe: 'NAZOVI ME',
    DrustveneMreze: 'DRUŠTVENE MREŽE',
    PrimanjeNovosti: 'PRIJAVI SE ZA PRIMANJE NOVOSTI',
    Prihvatamo: 'PRIHVATAMO',
    NasaPrica: 'Naša priča',
    KontentPet: 'Bio Plar smo osnovali 2019 godine s ciljem ponude visokokvalitetnih prirodnih preparata koje smo htjeli kupiti, ali nismo bili zadovoljni ponudom i kvalitetom dostupnih proizvoda na tržištu. Formula uspjeha naših proizvoda sastoji se od dva ključna elementa: vrhunskih sastojaka dokazanog djelovanja koje je teško pronaći na slobodnom tržištu te suradnje sa specijaliziranim farmaceutskim firmama, koje se bave proizvodnjom prirodnih preparata.\n\nNa ovaj način nudimo Vam vrhunske prirodne preparate koji uz redovito korištenje omogućuju podizanje kvalitete života.\n\n\n\nPronađite proizvode koji odgovaraju Vašim potrebama i uvjerite se u njihovu djelotvornost.',
    IdiNaWebShop: 'IDI NA WEB SHOP',
    PrikazujeSe: 'Prikazuje se',
    Proizvoda: 'proizvoda',
    SortirajPo: 'SORTIRAJ PO',
    Izaberite: 'IZABERITE',
    NazivAZ: 'Naziv A-Z',
    NazivZA: 'Naziv Z-A',
    CijenaNajvecaNajmanja: 'Cijena najveća - najmanja',
    CijenaNajmanjaNajveca: 'Cijena najmanja - najveća',
    BezSortiranja: 'Bez sortiranja',
    Osvjezi: 'Osvježi',
    Cijena: 'CIJENA',
    Naziv: 'NAZIV',
    Oboljenja: 'Oboljenja',
    Hemoroidi: 'Hemoroidi',
    KrvniPritisak: 'Krvni pritisak',
    Dijabetes: 'Dijabetes',
    Mrsavljenje: 'Mrsavljenje',
    Prostata: 'Prostata',
    Celulit: 'Celulit',
    Potencija: 'Potencija',
    Nedefinisano: 'Nedefinisano',
    KontaktForma: 'KONTAKT FORMA',
    Ime: 'Ime',
    Telefon: 'Telefon',
    Poruka: 'Poruka',
    Adresa: 'Adresa',
    RadnoVrijeme: 'Radno vrijeme',
    ZastoPrirodno: 'Zašto prirodno',
    KontentSest: 'Idealno, prirodne preparate proizvodili biste sami iz kvalitetnih sastojaka koje nalazite u prirodi.\n\nZbog vremena i novca koje je potrebno uložiti u proces pripreme, za većinu je ljudi proizvodnja preparata nemoguća misija koja je postala naš zadatak.\n\nVisokokvalitetni Bio Plar proizvodi pomažu Vam u ublažavanju svakodnevnih tegoba, poput hemoroida i pretilosti, koji nastaju zbog tempa i načina modernog života.',
    NekeOdPrednostiNasihProizvodaSu: 'Neke od prednosti naših proizvoda su:',
    Kolicina: 'Količina',
    BesplatnaDostava: 'Besplatna dostava',
    Obrisi: 'Obriši',
    SazetakNarudzbe: 'Sažetak narudžbe',
    KuponKod: 'Kupon kod',
    Primijeni: 'PRIMIJENI',
    Naruci: 'NARUČI',
    UkupnoBezPDV: 'Ukupno bez PDV-a',
    PDV: 'PDV',
    Dostava: 'Dostava',
    Besplatno: 'Besplatno',
    Ukupno: 'UKUPNO',
    PopustNa: 'Popust na',
    UneseniKodJeNevazeci: 'Uneseni kod je nevažeći...',
    UneseniKodJeIstekao: 'Uneseni kod je istekao...',
    KuponKod2: 'KUPON KOD:',
    NastaviNaOdabirDostave: 'NASTAVI NA ODABIR DOSTAVE',
    BrojKuce: 'Broj kuće',
    Grad: 'Grad',
    PostanskiBroj: 'Poštanski broj',
    PrekiniNarudzbu: 'PREKINI NARUDŽBU',
    NastaviNaPlacanje: 'NASTAVI NA PLAĆANJE',
    NacinPlacanja: 'Način plaćanja',
    PunoIme: 'Puno ime i prezime vlasnika kartice',
    DatumIsticanja: 'Datum isticanja',
    ZIP: 'ZIP',
    BrojKartice: 'Broj kartice',
    CVV: 'CVV',
    Ulica: 'Ulica',
    Email: 'Email',
    Napomena: 'Napomena',
    Plati: 'PLATI',
    PlatiPostaru: 'Platite poštaru pri preuzimanju paketa',
    PreuzimanjePDF: 'Vaš predračun će biti preuzet u narednih nekoliko sekundi...',
    UspjesnaNarudzba: 'Uspješno ste naručili',
    NastaviKupovinu: 'NASTAVI KUPOVINU',
    NazivKompanije: 'Naziv kompanije',
    AdresaKompanije: 'Adresa kompanije',
    PDVbroj: 'PDV broj',
    IDbroj: 'ID broj',
    SvaPoljaMorajuBitiPopunjena: 'Sva polja moraju biti popunjena!',
    PretplatiSe: 'PRETPLATI SE',
    Isporuka: 'Isporuka',
    Placanje: 'Plaćanje',
    DetaljiIsporuke: 'Detalji isporuke',
    KorisnickoIme: 'Korisnicko ime',
    Lozinka: 'Lozinka',
    Prijava: 'PRIJAVA',
    AkoNemateRacun: 'Ako nemate računa',
    RegistrirajKlikom: 'registrirajte se klikom ovde',
    Registracija: 'REGISTRACIJA',
    PrijaviteSe: 'PRIJAVITE SE',
    RegistrujSe: 'REGISTRIRAJTE SE',
    NemaNaStanju: 'Trenutno nema na stanju',
    DOSTUPNO: 'DOSTUPNO',
    DokazanaDjelotvornost: 'Dokazana djelotvornost',
    PorukaUspjesnoPoslana: 'Poruka uspješno poslana',
    NemaProizvodaZaPrikaz: 'Nema proizvoda za prikaz',
    NemateProizvodaUkosarici: 'Nemate proizvoda u košarici',
    Naruči: 'Naruči',
    DetaljiDostave: 'Detalji dostave',
    NaciniPlacanja: 'Načini plaćanja',
    VaseNarudzbe: 'Vase narudžbe',
    PostavkeProfila: 'Moj profil',
    VasiPodaciDostave: 'Vaši podaci dostave',
    Uredi: 'Uredi',
    PodaciNaplate: 'Podaci naplate',
    VasiNaciniPlacanja: 'Vaši načini plaćanja',
    VasTrenutniNacinPlacanja: 'Vaš trenutni način plaćanja',
    Izmijenite: 'Izmijenite:',
    DatumKreiranja: 'Datum kreiranja',
    Popust: 'Popust',
    PromijeniLozinku: 'Promijeni lozinku',
    SpremiLozinku: 'Spremi lozinku',
    TrenutnaLozinka: 'Trenutna lozinka',
    NovaLozinka: 'Nova lozinka',
    OdjaviSe: 'Odjavi se',
    Dobrodosli: 'Dobrodošli',
    Sacuvaj: 'Sačuvaj',
    DodajPodatkeZaDostavu: 'Dodaj podatke za dostavu',
    TrenutnoNematePodatkeZaDostavu: 'Trenutno nemate postavljene podatke za dostavu, popunite formular iznad',
    PostaviteZadaniNacinPlacanja: 'Postavite zadani način plaćanja',
    PodaciDostave: 'PODACI DOSTAVE',
    PodaciDostave2: 'Podaci dostave',
    PodaciPlacanja: 'PODACI PLACANJA',
    PodaciPlacanja2: 'Podaci placanja',
    PogledajArtikle: 'Pogledaj artikle',
    Proizvod: 'Proizvod',
    PreporucenoZaVas: 'PREPORUČENO ZA VAS',
    JeDodan: 'je dodan u vašu košaricu',
    IdiUkosaricu: 'IDI U KOŠARICU',
    Oproizvodu: 'O PROIZVODU',
    Upotreba: 'UPOTREBA',
    Sastojci: 'SASTOJCI',
    Upozorenja: 'UPOZORENJA',
    MisljenjaKupaca: 'MIŠLJENJA NAŠIH KUPACA',
    Sastojak: 'Sastojak',
    Upozorenje: 'Upozorenje:',
    NaKupovinu: 'na kupovinu',
    Ostvarite10popusta: 'Ostvarite 10% popusta prijavom na e-novosti!',
    PotvrdjujemPolitikaPrivatnosti: 'Potvrđujem da sam upoznat/a s politikom privatnosti i dopuštam uporabu e-mail adrese',
    UspjesnoSteSePrijavili: 'Uspješno ste se prijavili!',
    CCphoneNumber: '+38532373309',
    Sluh: 'Sluh',
    Vid: 'Vid',
    PromjenaLozinke: 'Promjena lozike',
    ImatePitanja: 'Imate pitanje o Vašoj narudžbi',
    PozoviteNas: 'Pozovite nas na broj telefona prikazan iznad i naši agenti će Vam pružiti sve potrebne informacije.',
    sigurna100kupovina: '100% sigurna kupovina',
    Pobrinuli: 'Pobrinuli smo se da Vaši podaci budu zaštićeni, tako da možete sigurno kupovati na našim web stranicama. Vaši podaci i privatnost neće biti dijeljeni.',
    PovratNovca: 'Povrat novca',
    UkolikoIzBiloKojeg: 'Ukoliko iz bilo kojeg razloga niste zadovoljni proizvodom, možete ga vratiti u roku od 15 dana, a mi Vam vraćamo novac.',
    PlacanjeNaRate: 'Plaćanje na rate',
    NudimoVamPlacanjeNaRate: 'Nudimo Vam nekoliko različitih načina plaćanja, a Vi odaberite koji Vam najviše odgovara. Možete platiti prilikom dostave, na do 24 rate ili online putem Vaše kartice. Kada je plaćanje na 24 rate u pitanju, broj rata možete dogovoriti u razgovoru sa našim ljubaznim operaterima.',
    UspjesnoSteNarucili: 'Uspješno ste naručili!',
    KolicinaTabela: 'Količina (1 kapsula)',
    GljivicnaInfekcija: 'Gljivična infekcija',
    Koza: 'Koža',
    ProizvodJeNaStanju: 'Proizvod je na stanju',
    IsporukaDanas: 'Isporuka danas',
    IliNazovite: 'Ili nazovite i naručite na: ',
    DostavaUroku24sata: 'Dostava u roku 24 sata na vašu adresu'
}

export {croatian};